body {
  overflow-x: clip;
}
.modal-backdrop.show {
  opacity: 0.2 !important;
}
div[role="menu"] {
  outline: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  cursor: pointer;
}

.tokenomics-pagination ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tokenomics-pagination li:last-child,
.tokenomics-pagination li:first-child {
  display: none;
}
.tokenomics-pagination li a {
  min-width: 40px;
  height: 40px;
  white-space: nowrap;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
}
.tokenomics-pagination li:not(:first-child) a {
  margin-left: -1px;
}
li.previous.disabled a,
li.next.disabled a {
  background-color: #ddd;
  cursor: default;
}
li.selected a {
  background-color: #0080a6;
  color: #fff;
}

/* datatable css */
.rdt_Table .rdt_TableHeadRow > div,
.rdt_Table .rdt_TableBody .rdt_TableRow > div {
  border-right: 1px solid #e5e7eb;
  padding: 8px;
  justify-content: center;
  white-space: nowrap;
}
.rdt_Table .rdt_TableHeadRow > div:nth-child(8),
.rdt_Table .rdt_TableBody .rdt_TableRow > div:nth-child(8) {
  justify-content: flex-start;
}
.rdt_Table .rdt_TableBody .rdt_TableRow {
  border-bottom: 1px solid #e5e7eb;
}
.rdt_Table .rdt_TableBody .rdt_TableRow > div:nth-child(2) {
  color: blue;
}
.rdt_Table .rdt_TableHeadRow > div:last-child,
.rdt_Table .rdt_TableBody .rdt_TableRow > div:last-child {
  border-right: 0;
}
.rdt_Table .rdt_TableHeadRow,
.rdt_Table .rdt_TableRow {
  min-height: unset;
}

.cell-gray {
  background-color: #c9c9c9;
  color: black;
}
.cell-red {
  background-color: #ffadaf;
  color: #b6252a;
}
